import {
  Box,
  colors,
  ContentText,
  fontWeights,
  IconArrowRight,
  IconHome,
  IconPin,
  Link,
  px2rem,
} from '@fortum/elemental-ui';
import KeyValueRow from '@components/KeyValueRow';
import Divider from '@components/Divider';
import KeyValueColumn from '@components/KeyValueColumn';
import FormattedEnhancedMessage from '@utils/intl-extenders/FormattedEnhancedMessage';
// import EmailChangeBox from '@containers/EmailChangeBox';
import React, { useState } from 'react';
import AddressChangeBox from '@containers/AddressChangeBox';
import Consents from '@components/Consents';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Spinner from '@components/Spinner';
import { getProperPriceString } from '@utils/prices';
import { GTMButton } from '@components/GTMComponents';
import KeyValuePrice from '@components/KeyValuePrice';
import useContractContext from '@hooks/useContractContext';
import useIsAdminPage from '@hooks/useIsAdminPage';
import ReadMoreContractModal from '@containers/ReadMoreContractModal';
import useRoutes from '@hooks/useRouting';
import DividerDashed from '@components/DividerDashed';
import { Address, ContractDetails, CustomerType } from '../../types/api';
import ErrorFallback from '../ErrorFallback';
import { UpdateEmailClickable } from './styled';
import { ISpecialPricesProps, MappedData } from './ContractSummary.types';
import { B2BInfoBox } from './B2BInfoBox';
import { B2CInfoBox } from './B2CInfoBox';

function SpecialPrices({ prices }: ISpecialPricesProps) {
  const { t } = useTranslation();

  if (!prices || !prices.length) return null;
  return (
    <>
      <DividerDashed />
      {Object.entries(prices).map(([key, value]) => (
        <KeyValueRow key={key} label={t(`common.${key}`)}>
          {getProperPriceString(value, t)}
        </KeyValueRow>
      ))}
    </>
  );
}

function getName(name?: string | string[]) {
  if (!name) return '';
  if (Array.isArray(name)) return name.join(', ');
  return name;
}

function formatAddress(address: Address) {
  if (!address) return '';
  return `${address.streetName || ''} ${address.houseNumber || ''} ${
    address.houseLetter || ''
  } ${address.residence || ''}, ${address.zipCode || ''}, ${
    address.cityName || ''
  }`;
}

const ContractStartsBox = ({
  contractDetails,
}: {
  contractDetails: ContractDetails;
}) => {
  const { t } = useTranslation();
  const contentfulCategory = 'contractDetails';
  function getContractStartDays() {
    if (!contractDetails) return 0;
    const from = dayjs(contractDetails.duration?.from);

    const hourDiff = from.diff(dayjs(), 'hour');

    const daysDiff = Math.ceil(hourDiff / 24);

    return daysDiff;
  }

  const contractStartsDays = getContractStartDays();

  const getContractStartsDaysString = () => {
    const isSame = dayjs(contractDetails.duration?.from).isSame(dayjs(), 'day');

    if (isSame) {
      return t(`${contentfulCategory}.today`);
    }
    if (contractStartsDays === 1) {
      return t(`${contentfulCategory}.tomorrow`);
    }
    if (contractStartsDays < 0) {
      return dayjs(contractDetails.duration?.from).format('DD.MM.YYYY');
    }
    return `${contractStartsDays} ${t(`${contentfulCategory}.days`)}`;
  };

  if (
    contractStartsDays === 0 ||
    contractStartsDays === 1 ||
    contractStartsDays < 0
  ) {
    return (
      <>
        <ContentText
          fontWeight={400}
          fontSize="14px"
          color="#333333"
          textAlign="center"
        >
          {t(
            contractStartsDays < 0
              ? `${contentfulCategory}.contractStarted`
              : `${contentfulCategory}.contractStarts`
          )}
        </ContentText>
        <ContentText
          color="#1E4F45"
          fontWeight={500}
          fontSize="29px"
          textAlign="center"
          marginBottom="1rem"
        >
          {getContractStartsDaysString()}
        </ContentText>
      </>
    );
  }

  return (
    <>
      <ContentText
        color="#1E4F45"
        fontWeight={500}
        fontSize="29px"
        textAlign="center"
      >
        {getContractStartsDaysString()}
      </ContentText>
      <ContentText
        fontWeight={400}
        fontSize="14px"
        color="#333333"
        textAlign="center"
        marginBottom="1rem"
      >
        {t(`${contentfulCategory}.untilContractStarts`)}
      </ContentText>
    </>
  );
};

function ContractSummary() {
  const contentfulCategory = 'contractDetails';
  const [showAddressChange, setShowAddressChange] = React.useState(false);
  const { t, i18n } = useTranslation();
  const { contractDetails, isLoading, isError } = useContractContext();
  const isAdminContext = useIsAdminPage();
  const [readMoreOpen, setReadMoreOpen] = useState(false);
  const { redirectToNext } = useRoutes();

  if (isError) return <ErrorFallback />;
  if (isLoading || !contractDetails) return <Spinner />;

  const mappedData: MappedData = {
    customerId: contractDetails.customerId,
    electricityPrice: contractDetails.electricityPrice,
    baseFee: contractDetails.baseFee,
    duration: {
      from: dayjs(contractDetails.duration?.from).format('D.MM.YYYY') || '',
      to: contractDetails.duration?.to
        ? dayjs(contractDetails.duration.to).format('D.MM.YYYY')
        : null,
    },
    contractNumber:
      contractDetails.contractCode || contractDetails.contractNumber,
    name: getName(contractDetails.name),
    email: contractDetails.email || '',
    address: contractDetails.address || '',
    meteringPointNo: contractDetails.meteringPointId || '',
    productName: contractDetails.productName || '',
    specialElectricityPrice: contractDetails.specialElectricityPrice || null,
    customerType: contractDetails.customerType,
  };

  const serializedProductName = mappedData.productName
    .toLowerCase()
    .replaceAll(' ', '-');

  // eslint-disable-next-line no-nested-ternary
  const extraPriceText = i18n.exists(`${contentfulCategory}.extraPriceText`)
    ? t(`${contentfulCategory}.extraPriceText`)
    : null;

  function handleAddressChangeClick() {
    setShowAddressChange(true);
  }

  function onReadMoreModalClose() {
    setReadMoreOpen(false);
  }

  const { baseFee } = mappedData;

  const getBaseFeeData = () => {
    if (!baseFee) return null;
    const baseFeeDiscount = getProperPriceString(baseFee.price, t, true);
    const baseFeeFrom = baseFee
      ? dayjs(baseFee.price.discountFrom).format('D.MM.YYYY')
      : 'None';
    const baseFeeTo = baseFee
      ? dayjs(baseFee.price.discountTo).format('D.MM.YYYY')
      : 'None';

    return { baseFeeDiscount, baseFeeFrom, baseFeeTo };
  };

  const getElectricityPriceData = () => {
    if (!mappedData.electricityPrice) return null;
    const electricityPriceDiscount = getProperPriceString(
      mappedData.electricityPrice.price,
      t,
      true
    );
    const electricityPriceFrom = mappedData.electricityPrice?.price
      ?.discountFrom
      ? dayjs(mappedData.electricityPrice.price.discountFrom).format(
          'D.MM.YYYY'
        )
      : null;
    const electricityPriceTo = mappedData.electricityPrice?.price?.discountTo
      ? dayjs(mappedData.electricityPrice.price.discountTo).format('D.MM.YYYY')
      : null;

    return {
      electricityPriceDiscount,
      electricityPriceFrom,
      electricityPriceTo,
    };
  };

  const monthlyFeeData = getBaseFeeData();
  const electricityPriceData = getElectricityPriceData();

  return (
    <>
      <Box
        backgroundColor={colors.snowWhite}
        boxShadow="0px 0px 27px 8px rgba(0, 0, 0, 0.1)"
        padding="1rem"
        borderRadius="0.5rem"
        marginTop="1rem"
      >
        <>
          <ContractStartsBox contractDetails={contractDetails} />
          <Divider />
        </>
        <Box marginTop="1rem">
          <ContentText
            size={20}
            color="#1E4F45"
            fontWeight={500}
            marginBottom="0.5rem"
          >
            {mappedData.productName}
          </ContentText>
          <ContentText size={14} color="#333333">
            {t(`products.${serializedProductName}.description`)}
          </ContentText>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            fontSize="14px"
            marginTop="0.5rem"
            color="#0F7A5A"
            onClick={() => {
              setReadMoreOpen(true);
            }}
            fontWeight={400}
          >
            <FormattedEnhancedMessage i18nKey="common.readMore" />
          </Link>
        </Box>
        <DividerDashed />
        {mappedData.electricityPrice ? (
          <>
            <KeyValuePrice
              label={t('common.electricityPrice')}
              priceObject={mappedData.electricityPrice.price}
              additionalText={
                electricityPriceData?.electricityPriceFrom
                  ? t(`${contentfulCategory}.electricityPriceDiscount`, {
                      from: electricityPriceData.electricityPriceFrom,
                      to: electricityPriceData.electricityPriceTo,
                      discount: electricityPriceData.electricityPriceDiscount,
                    })
                  : ''
              }
            />
            <DividerDashed />
          </>
        ) : null}
        {mappedData.baseFee ? (
          <KeyValuePrice
            label={t('common.flatFee')}
            priceObject={mappedData.baseFee.price}
            additionalText={
              baseFee.price.discountFrom
                ? t(`${contentfulCategory}.feeFreeSixMonths`, {
                    from: monthlyFeeData?.baseFeeFrom,
                    to: monthlyFeeData?.baseFeeTo,
                    discount: monthlyFeeData?.baseFeeDiscount,
                  })
                : ''
            }
          />
        ) : null}

        <SpecialPrices prices={mappedData.specialElectricityPrice} />
      </Box>
      {/* <EmailChangeBox show={showEmailChange} setShow={setShowEmailChange} /> */}
      <AddressChangeBox
        show={showAddressChange}
        setShow={setShowAddressChange}
      />
      <ReadMoreContractModal
        productName={serializedProductName}
        productTitle={mappedData.productName}
        open={readMoreOpen}
        onClose={onReadMoreModalClose}
      />
      <Box margin="1rem 0">
        <KeyValueRow label={t('common.duration')}>
          {mappedData.duration.from}
          {mappedData.duration.to ? (
            <>
              <IconArrowRight
                margin={`0 ${px2rem(12)}`}
                size={16}
                color={colors.emeraldGreen}
              />
              {mappedData.duration.to}
            </>
          ) : null}
        </KeyValueRow>
        <KeyValueRow label={t('common.customerId')}>
          {mappedData.customerId}
        </KeyValueRow>
        {extraPriceText && (
          <ContentText size="s" color={colors.plumPurple}>
            {extraPriceText}
          </ContentText>
        )}
      </Box>
      <Divider />
      {mappedData.customerType === CustomerType.BUSINESS ? (
        <B2BInfoBox mappedData={mappedData} />
      ) : (
        <B2CInfoBox mappedData={mappedData} />
      )}
      <Divider />
      <Box margin="1rem 0">
        <ContentText
          size="xl"
          weight={fontWeights.bold}
          marginBottom="1rem"
          color={colors.emeraldGreen}
        >
          <FormattedEnhancedMessage i18nKey="common.powerDeliveryAddress" />
        </ContentText>
        <KeyValueColumn
          label={t('common.address')}
          icon={<IconHome color={colors.emeraldGreen} marginRight="0.75rem" />}
        >
          {formatAddress(mappedData.address)}
        </KeyValueColumn>
        <KeyValueColumn
          label={t('common.meteringPointNo')}
          icon={<IconPin color={colors.emeraldGreen} marginRight="0.75rem" />}
        >
          {mappedData.meteringPointNo}
        </KeyValueColumn>
        <Box marginTop="1rem" display="flex">
          <ContentText size="s" color={colors.emperorGrey}>
            <FormattedEnhancedMessage
              i18nKey={`${contentfulCategory}.wrongDeliveryAddress`}
            />
            &nbsp;
            <UpdateEmailClickable onClick={handleAddressChangeClick}>
              <FormattedEnhancedMessage
                i18nKey={`${contentfulCategory}.wrongDeliveryAddressLink.text`}
              />
            </UpdateEmailClickable>
          </ContentText>
        </Box>
      </Box>
      <GTMButton
        width="-webkit-fill-available"
        margin="1rem"
        onClick={redirectToNext}
        contentfulKey={`${contentfulCategory}.button`}
        disabled={isAdminContext}
        borderRadius="28px"
      >
        <FormattedEnhancedMessage i18nKey="common.next" />
      </GTMButton>
      <Consents
        prefix={mappedData.customerType === CustomerType.BUSINESS ? 'B2B_' : ''}
      />
    </>
  );
}

export default ContractSummary;
