import { Box, colors } from '@fortum/elemental-ui';
import styled from 'styled-components';

export const UpdateEmailClickable = styled.span`
  text-decoration-line: underline;
  cursor: pointer;
  color: ${colors.emperorGrey};
  font-weight: 700;
  font-size: 14px;
`;

export const Section = styled(Box)`
  padding: 1rem;
  border-bottom: 1px solid #c9c9c9;
`;
