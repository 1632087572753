import {
  Box,
  colors,
  ContentText,
  fontWeights,
  IconEnvironmentalFriendly,
  IconStarFilled,
} from '@fortum/elemental-ui';

interface Props {
  data: {
    name: string;
    date: string;
    content: string;
    footer: string;
  };
}

const Stars = () => (
  <Box display="flex" gap="11px">
    <IconStarFilled color={colors.plumPurple} />
    <IconStarFilled color={colors.plumPurple} />
    <IconStarFilled color={colors.plumPurple} />
    <IconStarFilled color={colors.plumPurple} />
    <IconStarFilled color={colors.plumPurple} />
  </Box>
);

const ReviewBox: React.FC<Props> = ({ data }) => {
  return (
    <Box
      border={`1px solid ${colors.fogGrey}`}
      padding="1rem"
      position="relative"
    >
      <ContentText size="xl" marginBottom="0.5rem">
        {data.name}
      </ContentText>
      <Stars />
      <ContentText size="s" color={colors.emperorGrey} marginBottom="1rem">
        {data.date}
      </ContentText>
      <ContentText weight={fontWeights.light} color={colors.inkGrey}>
        {data.content}
      </ContentText>
      <Box display="flex" alignItems="center" gap="1rem" marginTop="1rem">
        <IconEnvironmentalFriendly color={colors.oceanGreen} size="36px" />
        <ContentText color={colors.oceanGreen}>{data.footer}</ContentText>
      </Box>
    </Box>
  );
};

export default ReviewBox;
