import { Box } from '@fortum/elemental-ui';

const DividerDashed = () => {
  return (
    <Box
      border="1px dashed #555555"
      height="1px"
      width="100%"
      opacity={0.2}
      data-testid="divider"
      margin="8px 0"
    />
  );
};

export default DividerDashed;
