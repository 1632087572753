import {
  Box,
  ContentText,
  Header,
  Heading,
  Table,
  tableColumn,
  Button,
  Pagination,
  PaginationProps,
  CommonHeaderProps,
  Loader,
  Search,
  colors,
} from '@fortum/elemental-ui';
import useAdminContext from '@hooks/useAdminContext';
import useNotificationContext from '@hooks/useNotificationContext';
import { adminGetCustomers, adminSendSMS } from '@utils/API';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AdminCustomer, AdminGetCustomersResponse } from 'types/api';
import useDebounce from '@hooks/useDebounce';
import DateRangeFilter from '@components/DateRangeFilter';
import utc from 'dayjs/plugin/utc';
import BatchSmsSending from '@containers/AdminBatchSmsSending';
import AdminStats from '@containers/AdminStats';

dayjs.extend(utc);

const StyledPagination = styled(Pagination)<PaginationProps>`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const StyledHeader = styled(Header)<CommonHeaderProps>`
  border-bottom: 1px solid lightgray;
  padding-bottom: 1rem;
`;

function LeftComponent() {
  return <Heading>Admin page</Heading>;
}

function RightComponent() {
  const { logout } = useAdminContext();
  const navigate = useNavigate();
  const onLogoutClick = () => {
    logout();
    navigate('/admin/login');
  };

  return <Button onClick={onLogoutClick}>Logout</Button>;
}

function Admin() {
  const { authToken, logout, tokenSet } = useAdminContext();
  const [order, setOrder] = useState('DESC');
  const [orderBy, setOrderBy] = useState('createdAt');
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState('');
  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);
  const [data, setData] = useState<AdminGetCustomersResponse>(
    {} as AdminGetCustomersResponse
  );
  const [disabledRows, setDisabledRows] = useState<string[]>([]);
  const [rowLoading, setRowLoading] = useState<string | null>(null);
  const { setNotification } = useNotificationContext();
  const navigate = useNavigate();
  const debouncedQuery = useDebounce(query, 500);

  const pageSize = 15;

  const getData = useCallback(async () => {
    try {
      const resp = await adminGetCustomers({
        authToken,
        order,
        orderBy,
        page,
        size: pageSize,
        query: `${debouncedQuery}`,
        startDate: dateFrom,
        endDate: dateTo,
      });
      setData(resp);
    } catch (error) {
      if (
        error instanceof AxiosError &&
        (error.response?.status === 403 || error.response?.status === 401)
      ) {
        logout();
        navigate('/admin/login');
      }
    }
  }, [
    authToken,
    order,
    orderBy,
    logout,
    navigate,
    page,
    debouncedQuery,
    dateFrom,
    dateTo,
  ]);

  async function onSendSmsClick(row: AdminCustomer) {
    if (row.magicLink?.id) return;
    try {
      setRowLoading(row.id);
      await adminSendSMS({ id: row.id, authToken });
      setDisabledRows((prev) => [...prev, row.id]);
      setNotification({
        text: 'SMS sent',
        visible: true,
        icon: 'IconInfo',
      });
      await getData();
    } catch (error) {
      if (error instanceof AxiosError) {
        setNotification({
          text: error.response?.data?.message || 'Error when sending SMS',
          visible: true,
          color: colors.punchRed,
        });
      }
    } finally {
      setRowLoading(null);
    }
  }

  useEffect(() => {
    if (!tokenSet) return;
    getData();
  }, [getData, tokenSet, authToken]);

  const columns = [
    tableColumn((row: AdminCustomer) => row.contractCode)({
      key: 'contractCode',
      name: 'Contract code',
      sortable: true,
    }),
    tableColumn((row: AdminCustomer) => row.contractNo)({
      key: 'contractNo',
      name: 'Contract NO',
      sortable: true,
    }),
    tableColumn((row: AdminCustomer) => row.customerId)({
      key: 'customerId',
      name: 'Customer ID',
      sortable: true,
    }),
    tableColumn((row: AdminCustomer) => row.customerType)({
      key: 'customerType',
      name: 'Type',
      sortable: false,
    }),
    tableColumn((row: AdminCustomer) =>
      dayjs.utc(row.createdAt).format('D.MM.YYYY HH:mm:ss')
    )({
      key: 'createdAt',
      name: 'Created at',
      sortable: true,
    }),
    tableColumn((row: AdminCustomer) =>
      row.magicLink?.createdAt
        ? dayjs.utc(row.magicLink.createdAt).format('D.MM.YYYY HH:mm:ss')
        : 'Not sent'
    )({
      key: 'smsSent',
      name: 'SMS sent date',
      sortable: true,
    }),
    tableColumn((row: AdminCustomer) =>
      row.queueDate
        ? dayjs.utc(row.queueDate).format('D.MM.YYYY HH:mm:ss')
        : '-'
    )({
      key: 'queueDate',
      name: 'Sms scheduled at',
      sortable: false,
    }),

    tableColumn((row: AdminCustomer) =>
      rowLoading === row.id ? (
        <Loader marginLeft="4rem" />
      ) : (
        <>
          <Button
            onClick={() =>
              window.open(`/admin/preview/${row.id}`, '_blank', 'noreferrer')
            }
            marginRight="0.5rem"
          >
            Preview
          </Button>
          <Button
            disabled={
              !!row.magicLink?.id ||
              !!rowLoading ||
              disabledRows.includes(row.id)
            }
            onClick={() => onSendSmsClick(row)}
          >
            Send sms
          </Button>
        </>
      )
    )({
      key: 'actions',
      name: 'Actions',
    }),
  ];

  return (
    <>
      <StyledHeader
        leftComponent={LeftComponent}
        rightComponent={RightComponent}
      />
      <Box
        padding="1rem"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <BatchSmsSending />
        <AdminStats />
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          flexDirection={{
            default: 'column',
            xl: 'row',
          }}
        >
          <ContentText size="xl" marginBottom="2rem">
            Customers table ({data.count} rows)
          </ContentText>
          <Box display="flex">
            <DateRangeFilter
              fromValue={dateFrom}
              onFromChange={(from) => setDateFrom(from)}
              toValue={dateTo}
              onToChange={(to) => setDateTo(to)}
            />
            <Search
              name="search"
              width="auto"
              onChange={(e) => setQuery(e.target.value)}
              marginLeft="2rem"
            />
          </Box>
        </Box>
        <Table
          rows={data.result}
          getRowKey={(row) => row.id}
          columns={columns}
          sortBy={[]}
          sortOrder={[]}
          onSortOrderChange={() =>
            setOrder((prev) => (prev === 'ASC' ? 'DESC' : 'ASC'))
          }
          onSortByChange={(newOrderBy) => setOrderBy(newOrderBy[0] as string)}
          tableProps={{ width: '100%' }}
        />
        {data.result && (
          <StyledPagination
            active={page}
            pages={Math.ceil(data.count / pageSize)}
            onPageChange={(newPage) => setPage(newPage)}
          />
        )}
      </Box>
    </>
  );
}

export default Admin;
