import { Box, ContentText } from '@fortum/elemental-ui';
import FormattedEnhancedMessage from '@utils/intl-extenders/FormattedEnhancedMessage';
import { useTranslation } from 'react-i18next';
import { GTMButton } from '@components/GTMComponents';
import { Helmet } from 'react-helmet-async';
import AppSection from '@components/AppSection';

function B2CThankYou() {
  const contentfulCategory = 'thankYou';
  const { t } = useTranslation();
  const isFeedbackLoopEnabled = t('settings.B2CFeedbackLoopVisible') === 'true';

  async function onLoginButtonClick() {
    window.location.href = t(`${contentfulCategory}.button.login.link`);
  }

  async function onReadMoreButtonClick() {
    window.location.href = t(`${contentfulCategory}.button.readMore.link`);
  }

  return (
    <>
      {isFeedbackLoopEnabled ? (
        <Helmet>
          {/* <!-- Forsta Digital Feedback Program -->  */}
          <script
            id="cf-program-FtVH7p"
            src="https://cdn.horizons.confirmit.eu/loader?programKey=FtVH7p"
            async
            data-rh="true"
          />
          {/* <!-- End Forsta Digital Feedback Program --> */}
        </Helmet>
      ) : null}
      <AppSection />
      <Box textAlign="center" padding="0 1rem">
        {isFeedbackLoopEnabled ? <div id="ForstaContentOB" /> : null}
        <ContentText size="xl" marginBottom="0.5rem">
          <FormattedEnhancedMessage
            i18nKey={`${contentfulCategory}.footer.header`}
          />
        </ContentText>
        <ContentText size="xs" marginBottom="0.5rem">
          <FormattedEnhancedMessage
            i18nKey={`${contentfulCategory}.footer.text`}
          />
        </ContentText>
        <GTMButton
          contentfulKey={`${contentfulCategory}.button.login`}
          width="100%"
          onClick={onLoginButtonClick}
          borderRadius="28px"
        >
          <FormattedEnhancedMessage
            i18nKey={`${contentfulCategory}.button.login.text`}
          />
        </GTMButton>
        <GTMButton
          contentfulKey={`${contentfulCategory}.button.readMore`}
          width="100%"
          onClick={onReadMoreButtonClick}
          status="secondary"
          marginTop="0.5rem"
          borderRadius="28px"
        >
          <FormattedEnhancedMessage
            i18nKey={`${contentfulCategory}.button.readMore.text`}
          />
        </GTMButton>
      </Box>
    </>
  );
}

export default B2CThankYou;
