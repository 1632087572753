import { Box, colors, ContentText, fontWeights } from '@fortum/elemental-ui';
import Divider from '@components/Divider';
import KeyValueRow from '@components/KeyValueRow';
import VasCard from '@components/VasCard';
import FormattedEnhancedMessage from '@utils/intl-extenders/FormattedEnhancedMessage';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Spinner from '@components/Spinner';
import { useState } from 'react';
import BankActivationModal from '@containers/BankActivationModal';
import ContractTerms from '@components/ContractTerms';
import ReadMoreInvoiceModal from '@containers/ReadMoreInvoiceModal';
import ReadMoreVasModal from '@containers/ReadMoreVasModal';
import useAddonsContext from '@hooks/useAddonsContext';
import BankActivationBox from '@components/BankActivationBox';
import { GTMButton } from '@components/GTMComponents';
import useNotificationContext from '@hooks/useNotificationContext';
import useIsAdminPage from '@hooks/useIsAdminPage';
import useContractContext from '@hooks/useContractContext';
import useApi from '@hooks/useApi';
import useAppContext from '@hooks/useAppContext';
import B2BBankActivationBox from '@components/B2BBankActivationBox';
import FAQList from '@components/FAQList';
import { Addon, CustomerType } from '../../types/api';
import ErrorFallback from '../ErrorFallback';

/*
  @TODO: Refactor - split to smaller ones
*/
function Invoice() {
  const contentfulCategory = 'invoice';

  const { activateEInvoice, submitFlow, terminateAddon, addAddon } = useApi();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [showBankModal, setShowBankModal] = useState(false);
  const [invoiceActivated, setInvoiceActivated] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [readMoreOpen, setReadMoreOpen] = useState(false);
  const [vasDetails, setVasDetails] = useState<Addon | null>(null);
  const { setNotification } = useNotificationContext();
  const [eInvoiceLoading, setEInvoiceLoading] = useState(false);
  const isAdminPage = useIsAdminPage();
  const [submitLoading, setSubmitLoading] = useState(false);

  const {
    toActivate,
    toTerminate,
    handleToActivate,
    handleToTerminate,
    recommended,
    recommendedActivated,
    setRecommendedActivated,
  } = useAddonsContext();

  const { vasPromotionDisable } = useAppContext();

  const changesToAccept = !!toActivate.length || !!recommendedActivated;

  async function submitAddons() {
    await Promise.all(
      toActivate.map(async (addon) => {
        await addAddon(addon.templateId);
      })
    );

    await Promise.all(
      toTerminate.map(async (addon) => {
        await terminateAddon(addon.terminateTemplateNo, addon.tariffNo);
      })
    );
  }

  async function onSaveClick() {
    try {
      setSubmitLoading(true);
      await submitAddons();
      await submitFlow();
      navigate('/thank-you', { replace: true });
    } catch (error) {
      setNotification({
        text: 'An error has occurred!',
        visible: true,
        color: colors.punchRed,
      });
    } finally {
      setSubmitLoading(false);
    }
  }

  const { contractDetails, isLoading, isError } = useContractContext();

  if (isError) return <ErrorFallback />;
  if (isLoading || !contractDetails) return <Spinner />;

  const keyPrefix =
    contractDetails.customerType === CustomerType.BUSINESS ? 'B2B_' : '';

  const activeAddons = recommended
    ? [...contractDetails.activeAddons, recommended]
    : contractDetails.activeAddons;

  const inactiveAddons = recommended
    ? contractDetails.inactiveAddons.filter(
        (addon) => recommended.tariffId !== addon.tariffId
      )
    : contractDetails.inactiveAddons;

  function onVasModalClose() {
    setReadMoreOpen(false);
    setVasDetails(null);
  }

  return (
    <>
      <ReadMoreInvoiceModal
        open={!vasDetails && readMoreOpen}
        onClose={() => setReadMoreOpen(false)}
      />
      <ReadMoreVasModal
        addon={vasDetails}
        open={!!(readMoreOpen && vasDetails)}
        onClose={onVasModalClose}
        active={
          recommended?.tariffId === vasDetails?.tariffId
            ? recommendedActivated
            : vasDetails?.active
        }
      />
      <BankActivationModal
        show={showBankModal}
        setShow={setShowBankModal}
        loading={eInvoiceLoading}
        onActivate={async (selectedBank: string) => {
          try {
            setEInvoiceLoading(true);
            await activateEInvoice(selectedBank);
          } catch (error) {
            setNotification({
              visible: true,
              text: t('errors.cannotActivateInvoice'),
              color: colors.punchRed,
            });
          } finally {
            setEInvoiceLoading(false);
            setInvoiceActivated(true);
            setShowBankModal(false);
          }
        }}
      />
      <Box marginBottom="1rem">
        <ContentText
          size="xl"
          weight={fontWeights.bold}
          margin="1rem 0"
          color={colors.emeraldGreen}
        >
          <FormattedEnhancedMessage i18nKey={`${contentfulCategory}.about`} />
        </ContentText>
        {i18n.exists(
          `${contentfulCategory}.${keyPrefix}invoicingDescription`
        ) ? (
          <ContentText color={colors.emperorGrey} size="s" marginBottom="1rem">
            <FormattedEnhancedMessage
              i18nKey={t(
                `${contentfulCategory}.${keyPrefix}invoicingDescription`
              )}
            />
          </ContentText>
        ) : null}
        <KeyValueRow label={t(`${contentfulCategory}.invoiceFrequency.label`)}>
          {t(
            `${contentfulCategory}.invoiceFrequency.${contractDetails.invoiceYearly}_per_year`
          )}
        </KeyValueRow>
        {contractDetails.customerType === CustomerType.BUSINESS ? (
          <B2BBankActivationBox
            title={t(`${contentfulCategory}.B2B_activateInvoiceHeader`)}
            icon={t(`${contentfulCategory}.B2B_activateInvoiceIcon`)}
            onReadMoreClick={() => {
              setReadMoreOpen(true);
            }}
          />
        ) : (
          <BankActivationBox
            title={t(`${contentfulCategory}.activateInvoiceHeader`)}
            icon={t(`${contentfulCategory}.activateInvoiceIcon`)}
            buttonText={
              invoiceActivated
                ? t(`${contentfulCategory}.activateInvoiceButton.completed`)
                : t(`${contentfulCategory}.activateInvoiceButton.initial`)
            }
            description={t(`${contentfulCategory}.activateInvoiceDescription`)}
            onButtonClick={() => {
              setShowBankModal(true);
            }}
            onReadMoreClick={() => {
              setReadMoreOpen(true);
            }}
            activated={invoiceActivated}
          />
        )}
        <Divider />
        <ContentText
          size="xl"
          weight={fontWeights.bold}
          margin="1rem 0"
          color={colors.emeraldGreen}
        >
          <FormattedEnhancedMessage
            i18nKey={
              activeAddons.length
                ? `${contentfulCategory}.yourServices`
                : `${contentfulCategory}.noServices`
            }
          />
        </ContentText>
        {activeAddons.map((addon) => (
          <VasCard
            key={addon.tariffId}
            addon={addon}
            active={
              recommended?.tariffId === addon.tariffId
                ? recommendedActivated
                : addon.active
            }
            onReadMoreClick={() => {
              setReadMoreOpen(true);
              setVasDetails(addon);
            }}
            onSwitchChange={() => {
              if (recommended?.tariffId === addon.tariffId) {
                setRecommendedActivated((prev) => !prev);
                handleToActivate(addon);
              } else {
                handleToTerminate(addon);
              }
            }}
            readOnly={
              recommended?.tariffId === addon.tariffId
                ? false
                : !addon.terminateTemplateNo
            }
            disabled={isAdminPage}
          />
        ))}
        <Divider />
        {inactiveAddons.length && !vasPromotionDisable ? (
          <ContentText
            size="xl"
            weight={fontWeights.bold}
            margin="1rem 0"
            color={colors.emeraldGreen}
          >
            <FormattedEnhancedMessage i18nKey="common.youMightAlsoLike" />
          </ContentText>
        ) : null}
        {vasPromotionDisable
          ? null
          : inactiveAddons.map((addon) => (
              <VasCard
                addon={addon}
                key={addon.tariffId}
                active={addon.active}
                onReadMoreClick={() => {
                  setVasDetails(addon);
                  setReadMoreOpen(true);
                }}
                onSwitchChange={() => {
                  handleToActivate(addon);
                }}
                disabled={isAdminPage}
              />
            ))}
        <Divider />
        {/* <ContentText size="xl" weight={fontWeights.bold} margin="1rem 0">
          <FormattedEnhancedMessage
            i18nKey={`${contentfulCategory}.faqHeader`}
          />
        </ContentText> */}
        <FAQList />
      </Box>
      {changesToAccept && (
        <ContractTerms
          isB2B={contractDetails.customerType === CustomerType.BUSINESS}
          checked={termsAccepted}
          onChange={setTermsAccepted}
        />
      )}
      {/* {contractDetails.customerType === CustomerType.PRIVATE ? (
        <Button onClick={redirectOrder}>Order redirect</Button>
      ) : null} */}
      {submitLoading ? <Spinner overlay /> : null}
      <GTMButton
        color={colors.oceanGreen}
        width="-webkit-fill-available"
        margin="1rem 1rem 0.5rem"
        onClick={onSaveClick}
        borderRadius="28px"
        disabled={
          (changesToAccept && !termsAccepted) || isAdminPage || submitLoading
        }
        contentfulKey={`${contentfulCategory}.button`}
      >
        <FormattedEnhancedMessage i18nKey="common.saveAndFinish" />
      </GTMButton>
    </>
  );
}

export default Invoice;
