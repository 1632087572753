import BottomModal from '@containers/BottomModal';
import {
  Box,
  Button,
  colors,
  ContentText,
  fontWeights,
  InputField,
} from '@fortum/elemental-ui';
import useApi from '@hooks/useApi';
import useContractContext from '@hooks/useContractContext';
import useNotificationContext from '@hooks/useNotificationContext';
import FormattedEnhancedMessage from '@utils/intl-extenders/FormattedEnhancedMessage';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Address } from 'types/api';

interface Props {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const ContentContainer = styled(Box)`
  overflow-y: auto;
  padding: 2rem 2rem 1rem;

  @media screen and (max-height: 650px) {
    max-height: 350px;
  }

  @media screen and (max-height: 550px) {
    max-height: 300px;
  }
`;

const ButtonContainer = styled(Box)`
  background-color: #fff;
  filter: drop-shadow(0px -7px 20px rgba(0, 0, 0, 0.14));
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 1.5rem 2rem 1rem;
`;

function formatStreet(address?: Address) {
  if (!address) return '';
  return `${address.streetName || ''} ${address.houseNumber || ''} ${
    address.houseLetter || ''
  } ${address.residence || ''}`;
}

const AddressChangeBox: React.FC<Props> = ({ show, setShow }) => {
  const contentfulCategory = 'contractDetails.addressChange';
  const { t } = useTranslation();
  const { changeDeliveryAddress } = useApi();
  const navigate = useNavigate();
  const { setNotification } = useNotificationContext();
  const { contractDetails } = useContractContext();
  const defaultStreet = formatStreet(contractDetails?.address);
  const [street, setStreet] = useState(defaultStreet);
  const [postal, setPostal] = useState(contractDetails?.address?.zipCode || '');
  const [loading, setLoading] = useState(false);

  const submitDisabled =
    !street ||
    !postal ||
    (street === defaultStreet &&
      (postal === contractDetails?.address?.zipCode || postal === ''));

  const onSubmit = async () => {
    if (submitDisabled) return;
    try {
      setLoading(true);
      await changeDeliveryAddress({ streetAddress: street, zipcode: postal });
      navigate('/address-changed', { state: { street, postal } });
    } catch (error) {
      setNotification({
        text: t('errors.cannotChangeAddress'),
        visible: true,
        color: colors.punchRed,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <BottomModal
      show={show}
      setShow={setShow}
      contentProps={{ padding: 0 }}
      showCloseButton
    >
      <ContentContainer>
        <ContentText size="l" weight={fontWeights.bold} marginBottom="0.5rem">
          <FormattedEnhancedMessage i18nKey={`${contentfulCategory}.header`} />
        </ContentText>
        <ContentText marginBottom="1.5rem">
          <FormattedEnhancedMessage i18nKey={`${contentfulCategory}.content`} />
        </ContentText>
        <ContentText size="l" weight={fontWeights.bold} marginBottom="0.5rem">
          <FormattedEnhancedMessage i18nKey="common.newAddress" />
        </ContentText>
        <InputField
          name="street"
          label={t('common.streetAddress')}
          marginBottom="0.5rem"
          value={street}
          onChange={(e) => setStreet(e.target.value)}
        />
        <InputField
          name="postal"
          label={t('common.postalCode')}
          marginBottom="0.5rem"
          onChange={(e) => setPostal(e.target.value)}
          value={postal}
          type="number"
        />
      </ContentContainer>

      <ButtonContainer>
        <Button
          width="100%"
          onClick={onSubmit}
          disabled={submitDisabled || loading}
        >
          <FormattedEnhancedMessage i18nKey="common.change" />
        </Button>
      </ButtonContainer>
    </BottomModal>
  );
};

export default AddressChangeBox;
