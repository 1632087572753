import { Box, colors, ContentText, Link, LazyIcon } from '@fortum/elemental-ui';
import FormattedEnhancedMessage from '@utils/intl-extenders/FormattedEnhancedMessage';
import { useTranslation } from 'react-i18next';

interface ItemProps {
  icon: string;
  title: string;
  onReadMoreClick?: () => void;
}

const ReadMoreLink = ({ onClick }: { onClick?: () => void }) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <Link size="xs" color={colors.emeraldGreen} onClick={onClick}>
    <FormattedEnhancedMessage i18nKey="invoice.B2B_readMore" />
  </Link>
);

const B2BBankActivationBox: React.FC<ItemProps> = ({
  icon,
  title,
  onReadMoreClick,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      padding="1rem"
      border={`1px solid ${colors.fogGrey}`}
      borderRadius="0.5rem"
      margin="1rem 0"
      display="flex"
      flexDirection="column"
    >
      <Box display="flex" gap="1rem">
        <Box display="flex" alignItems="flex-start">
          <LazyIcon iconId={icon} color={colors.duskyBlue} size="3rem" />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexDirection="column"
        >
          <ContentText size="xl" color={colors.inkGrey}>
            {title}
          </ContentText>
          <Box textAlign="left" width="100%">
            <ReadMoreLink onClick={onReadMoreClick} />
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        marginTop="1rem"
        justify-content="center"
      >
        <ContentText color={colors.duskyBlue} size="l">
          {t('invoice.B2B_freeOfCharge')}
        </ContentText>
      </Box>
    </Box>
  );
};

export default B2BBankActivationBox;
