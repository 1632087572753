import {
  Heading,
  FortumLogo,
  Box,
  colors,
  ContentText,
  fontWeights,
} from '@fortum/elemental-ui';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GTMButton } from '@components/GTMComponents';
import { useCallback, useEffect, useState } from 'react';
import useQuery from '@hooks/useQuery';
import Spinner from '@components/Spinner';
import LanguageSwitcher from '@components/LanguageSwitcher';
import { BasicIconItem } from '@components/IconItems';
import useIsAdminPage from '@hooks/useIsAdminPage';
import LandingImage from '@assets/images/landingImage.svg';
import useAppContext from '@hooks/useAppContext';
import { BackgroundImage, Container } from './styled';
import ErrorFallback from '../ErrorFallback';

function LandingPage() {
  const { t } = useTranslation();
  const contentfulCategory = 'landing';
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isUrlValid, setIsUrlValid] = useState(true);
  const query: URLSearchParams = useQuery();
  const code = query.get('code');
  const isAdminPage = useIsAdminPage();
  const { authorize } = useAppContext();

  function onClick() {
    navigate('/contract-summary');
  }

  const featureItems = t<string, { text: string; icon: string }[]>(
    `${contentfulCategory}.featureItem`,
    {
      returnObjects: true,
    }
  );

  const doUrlValidation = useCallback(async () => {
    try {
      setLoading(true);
      if (!code) setIsUrlValid(false);
      else authorize(code, '0000');
    } catch (error) {
      setIsUrlValid(false);
    } finally {
      setLoading(false);
    }
  }, [code, authorize]);

  useEffect(() => {
    if (isAdminPage) return;
    doUrlValidation();
  }, [doUrlValidation, isAdminPage]);

  if (loading && !isAdminPage) return <Spinner />;

  if (!isUrlValid) return <ErrorFallback error="errors.wrongPincode.content" />;

  return (
    <Container>
      <FortumLogo
        marginBottom="2rem"
        textAlign="center"
        width="100%"
        color={colors.snowWhite}
        justifyContent="flex-start"
        paddingLeft="0"
        paddingBottom="0"
      />
      <Heading
        color={colors.snowWhite}
        textAlign="left"
        marginBottom="1rem"
        weight={fontWeights.medium}
        lineHeight={1.2}
      >
        {t(`${contentfulCategory}.header`)}
      </Heading>
      <ContentText
        color={colors.snowWhite}
        weight={fontWeights.light}
        marginBottom="2rem"
      >
        {t(`${contentfulCategory}.text`)}
      </ContentText>
      {Array.isArray(featureItems)
        ? featureItems.map((item) => (
            <BasicIconItem
              key={item.icon + item.text}
              icon={item.icon}
              contentProps={{ fontWeight: 300 }}
            >
              {item.text}
            </BasicIconItem>
          ))
        : null}
      <GTMButton
        onClick={onClick}
        contentfulKey={`${contentfulCategory}.button`}
        disabled={isAdminPage}
        borderRadius="28px"
        width="100%"
        boxShadow="0px 0px 27px 8px rgba(255, 255, 255, 0.3)"
        marginTop="40px"
        marginBottom="2rem"
        invert
        zIndex={1}
      >
        {t(`${contentfulCategory}.buttonText`)}
      </GTMButton>
      {/* eslint-disable-next-line no-nested-ternary */}
      <BackgroundImage src={LandingImage} alt="woman-icon" />
    </Container>
  );
}

export default LandingPage;
