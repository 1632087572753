import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import { API_URL, commonHeaders } from '@utils/API';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: localStorage.getItem('lng') || 'nb-NO',
    fallbackLng: 'nb-NO',
    load: 'currentOnly',
    debug: false,
    backend: {
      loadPath: `${API_URL}/content/{{lng}}`,
      customHeaders: commonHeaders,
    },
    react: {
      useSuspense: true,
    },
    interpolation: {
      escapeValue: false,
    },
  });

i18n.on('languageChanged', (lng) => {
  document.documentElement.setAttribute('lang', lng);
});

export default i18n;
