import { Box, colors, ContentText } from '@fortum/elemental-ui';
import { getProperPriceString, hasDiscount } from '@utils/prices';
import { useTranslation } from 'react-i18next';
import { PriceObject } from 'types/api';

interface Props {
  label: string;
  priceObject: PriceObject;
  additionalText?: string;
}

const KeyValuePrice: React.FC<Props> = ({
  label,
  priceObject,
  additionalText,
}) => {
  const { t } = useTranslation();

  const basePrice = getProperPriceString(priceObject, t);
  const priceAfterDiscount = getProperPriceString(priceObject, t, true);
  const hasDiscountValue = hasDiscount(priceObject);

  if (!basePrice) return null;

  return (
    <Box display="flex" justifyContent="space-between">
      <ContentText fontWeight={400}>{label}</ContentText>
      <Box textAlign="right">
        <ContentText fontSize="14px" color="#333333">
          {hasDiscountValue ? priceAfterDiscount : basePrice}
        </ContentText>
        {hasDiscountValue ? (
          <ContentText
            fontWeight={400}
            fontSize="14px"
            color="#757575"
            style={{ textDecoration: 'line-through' }}
          >
            {basePrice}
          </ContentText>
        ) : null}
        <ContentText fontWeight={400} fontSize="14px" color={colors.plumPurple}>
          {additionalText}
        </ContentText>
      </Box>
    </Box>
  );
};

export default KeyValuePrice;
